import React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import { graphql } from "gatsby";
// import Info from "../../components/case-study/info";
import HeaderCaseStudy from "../../components/headers/header-case-study";
import LogoAegis from "../../assets/images/case-study/proserv.png";
import BgProserv from "../../assets/images/case-study/proserv-header.png";
import ImgProservAegis from "../../assets/images/case-study/proserv-aegis.png";
import ImgPipes from "../../assets/images/case-study/aegis-proserv-2.jpg";
import ImgGear from "../../assets/images/case-study/aegis-about.jpg";
import TextImage from "../../components/case-study/text-image";
// import Testimonial from "../../components/case-study/testimonial";
import { useTranslation } from "react-i18next";
// Header Content

const Aegis = () => {
  const { t } = useTranslation();

  const hTitle = `${t("caseStudy.aegis.title")}`;
  const hDescription = `${t("caseStudy.aegis.description")}`;

  const infoContent = {
    industry: `${t("caseStudy.aegis.info.industry")}`,
    wentLive: `${t("caseStudy.aegis.info.wentLive")}`,
    stage: `${t("caseStudy.aegis.info.stage")}`,
    services: [
      {
        service: `${t("caseStudy.aegis.info.services.first")}`,
      },
      {
        service: `${t("caseStudy.aegis.info.services.second")}`,
      },
    ],
  };

  const summaryContent = {
    title: `${t("caseStudy.aegis.summary.title")}`,
    shortText: `${t("caseStudy.about")}`,
    text: `${t("caseStudy.aegis.summary.text")}`,
    image: ImgProservAegis,
    soloImage: true,
  };

  const aboutProjectContent = {
    title: `${t("caseStudy.aegis.aboutProject.title")}`,
    text: `${t("caseStudy.aegis.aboutProject.firstText")}`,
    // secondText: `${t("caseStudy.aegis.aboutProject.secondText")}`,
    image: ImgPipes,
    soloImage: false,
  };

  const aboutCompanyContent = {
    title: "Proserv Aegis",
    shortText: `${t("caseStudy.about")}`,
    text: `${t("caseStudy.aegis.aboutCompany.firstText")}`,
    // secondText: `${t("caseStudy.aegis.aboutCompany.secondText")}`,
    image: ImgGear,
  };

  // const outcomeContent = {
  //   image: ImgPipes,
  //   centered: true,
  // };
  // const gearImgContent = {
  //   image: ImgGear,
  //   soloImage: true,
  //   centered: true,
  // };
  // const testimonialContent = {
  //   fullName: "Predrag Kovačić,",
  //   position: `${t("caseStudy.qcc.testimonial.position")}`,
  //   text: `${t("caseStudy.qcc.testimonial.text")}`,
  // };

  // const outcomeTitle = {
  //   title: `${t("caseStudy.aegis.outcome.title")}`,
  // };

  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderCaseStudy
        hTitle={hTitle}
        hDescription={hDescription}
        bgImage={BgProserv}
        logo={LogoAegis}
        infoContent={infoContent}
      />
      {/* <Info infoContent={infoContent} /> */}
      <TextImage
        textImageContent={summaryContent}
        cssModifer="-proserv -summary"
      />
      <TextImage textImageContent={aboutProjectContent} cssModifer="-project" />
      {/* <TextImage textImageContent={gearImgContent} cssModifer="-img" /> */}
      <TextImage textImageContent={aboutCompanyContent} cssModifer="-about" />
      {/* <TextImage textImageContent={outcomeContent} cssModifer="-about -image" /> */}
      {/* <TextImage textImageContent={outcomeTitle} cssModifer="-outcome" /> */}
      {/* <Testimonial testimonialContent={testimonialContent} /> */}
    </Layout>
  );
};

export default Aegis;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
