import React, { Fragment } from "react";
// import Image from "gatsby-image";

const TextImage = ({ textImageContent, cssModifer }) => {
  return (
    <section className={`m-textImage ${cssModifer ? cssModifer : ""}`}>
      <div className="_wr">
        <div
          className={`_w m-textImage__content ${
            textImageContent.centered ? "-centered" : ""
          } ${textImageContent.soloImage ? "-soloImage" : ""}`}
        >
          {textImageContent.image && textImageContent.soloImage && (
            <Fragment>
              <div className="_12 m-textImage__description">
                <h2>{textImageContent.title}</h2>
                <span className="a-line -red"></span>
                {/* {textImageContent.shortText && (
                  <p className="a-text -black">{textImageContent.shortText}</p>
                )} */}
                <p className="m-textImage__text">{textImageContent.text}</p>
              </div>
              <div className="_12 m-textImage__image">
                <img src={textImageContent.image} alt="case study" />
              </div>
            </Fragment>
          )}
          {!textImageContent.centered && (
            <Fragment>
              <div className="m-textImage__left _l5">
                <h3>{textImageContent.title}</h3>
                <span className="a-line -red"></span>
                {/* {textImageContent.shortText && (
                  <p className="a-text -black">{textImageContent.shortText}</p>
                )} */}
                <p className="m-textImage__text">{textImageContent.text}</p>
                <p className="m-textImage__text">
                  {textImageContent.secondText}
                </p>
              </div>
              <div
                className="m-textImage__img _l6"
                style={{
                  backgroundImage: `url("${textImageContent.image}")`,
                }}
              >
                {/* <img src={textImageContent.image} alt="case study" /> */}
              </div>
              {/* <div className="_m6">
                <p className="m-textImage__text">{textImageContent.text}</p>
              </div> */}
              {/* {textImageContent.secondText && (
                <div className="_m6">
                  <p className="m-textImage__text">
                    {textImageContent.secondText}
                  </p>
                </div>
              )} */}
            </Fragment>
          )}
          {textImageContent.centered && !textImageContent.image && (
            <Fragment>
              {textImageContent.image && (
                <div className="_12 m-textImage__content--image">
                  <img src={textImageContent.image} alt="case study" />
                </div>
              )}
              {textImageContent.title && (
                <div className="_m8">
                  <h2>{textImageContent.title}</h2>
                  {textImageContent.shortText && (
                    <p className="a-text -black">
                      {textImageContent.shortText}
                    </p>
                  )}
                </div>
              )}
              {textImageContent.text && (
                <div className="_m10">
                  <p className="m-textImage__text">{textImageContent.text}</p>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

export default TextImage;
