import React from "react";
import PropTypes from "prop-types";
import Services from "../../assets/images/case-study/services.svg";
import { useTranslation } from "react-i18next";

const HeaderCaseStudy = ({
  hTitle,
  hDescription,
  bgImage,
  logo,
  infoContent,
}) => {
  const { t } = useTranslation();
  return (
    <header className="m-headerCaseStudy">
      <div
        className="_wr m-headerCaseStudy__wrapper"
        // style={{ backgroundImage: `url("${bgImage}")` }}
      >
        <div className="_w m-headerCaseStudy__content">
          <div className="_xs10 _s8 _l5">
            <img src={logo} alt="Customer logo" />
            <h1 className="m-headerCaseStudy__content--title">{hTitle}</h1>
            <p className="m-headerCaseStudy__content--description">
              {hDescription}
            </p>
            <div className="m-headerCaseStudy__content--projectScope">
              <div>
                <Services />
                <span className="-title">{t("caseStudy.services")}</span>
              </div>
              <div className="-servicesWrapper">
                {infoContent.services.map(({ service }, key) => (
                  <span className="-info" key={key}>
                    {service}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div
            className="m-headerMarkets__bkg -caseStudy _m6"
            style={{ backgroundImage: `url("${bgImage}")` }}
          ></div>
        </div>
      </div>
    </header>
  );
};

HeaderCaseStudy.propTypes = {
  hTitle: PropTypes.string,
  hSubtitle: PropTypes.string,
  hTextOne: PropTypes.string,
  hTextTwo: PropTypes.string,
};

export default HeaderCaseStudy;
